import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ModalProvider } from "hooks/useModal";
import store from "store";
import "./i18n";
import "./assets/style/main.scss";

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div></div>}>
      <ModalProvider>
        <App />
      </ModalProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
