import React from "react";
import Select from "react-select";
import { useFormikContext, useField } from "formik";
import { selectStyle } from "./selectStyle";

export const SelectFormik = (props) => {
  const { name = "", onChange, options, ...other } = props;
  const form = useFormikContext();
  const [field] = useField(name);

  const handleChange = (e) => {
    form.setFieldValue(name, e);
    if (onChange) onChange(e);
  };

  const inputValue = field.value || "";

  const evalProps = {
    ...field,
    ...other,
    value: inputValue,
    id: name,
    onChange: handleChange,
  };

  const customStyles = {
    control: () => ({
      height: "3.125rem",
      display: "flex",
      border: "1px solid #e4e4e4",
    }),
  };

  return (
    <>
      <Select
        styles={selectStyle}
        components={{ IndicatorSeparator: () => null }}
        options={options}
        {...evalProps}
      />
    </>
  );
};
