import React from "react";
import { useDispatch, useSelector } from "react-redux";
import checkIcon from "assets/img/common/check.svg";
import colorEmpty from "assets/img/common/color.png";
import { baseURL } from "api";
import { setPeepholeTypeId } from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import s from "./index.module.scss";

const Peephole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.configurator.loading);
  const peepholeTypes = useSelector((state) => state.configurator.peepholeTypes);
  const peepholeTypeId = useSelector((state) => state.configurator.peepholeTypeId);

  const handleSetPeepholeTypeId = (item) => {
    if (!loading && !item?.locked) dispatch(setPeepholeTypeId(item.id));
  };

  return (
    <div className={s.container}>
      <div className={s.peephole__inner}>
        <h4 className={s.peephole__title}>{t("peephole")}</h4>

        <div className={s.peephole__list}>
          {!!peepholeTypes.length &&
            peepholeTypes.map((item) =>
                !item?.locked
                ?
                <div
                  key={item.id}
                  className={s.peephole__item}
                  onClick={() => handleSetPeepholeTypeId(item)}>
                  <div className={clsx(s.peephole__img, item?.locked && s.locked)}>
                    {peepholeTypeId === item.id && (
                      <img className={s.check} src={checkIcon} alt="" />
                    )}

                    <img
                      className={clsx(s.peephole__image, item?.locked && s.locked)}
                      src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                      alt="lock"
                    />
                  </div>
                  <p className={s.peephole__item__title}>{item.name}</p>
                  <p className={s.peephole__alert}>
                    {item?.locked && "! не доступно в данной конфигурации"}
                  </p>
                </div>
                    :
                    null
            )}
        </div>
      </div>
    </div>
  );
};

export default Peephole;
