import model from "assets/img/sidebar/model.svg";
import parameters from "assets/img/sidebar/parameters.svg";
import finishType from "assets/img/sidebar/finishType.svg";
import color from "assets/img/sidebar/color.svg";
import casingColor from "assets/img/sidebar/casingColor.svg";
import handles from "assets/img/sidebar/handles.svg";
import handlesColor from "assets/img/sidebar/handlesColor.svg";
import locks from "assets/img/sidebar/locks.svg";
import loops from "assets/img/sidebar/loops.svg";
import accessories from "assets/img/sidebar/accessories.svg";
import information from "assets/img/sidebar/information.svg";

export const sidebarTabs = [
  { icon: color, title: "door type", id: "type" },
  { icon: color, title: "style", id: "style" },
  { icon: model, title: "model", id: "model" },
  { icon: finishType, title: "finishType", id: "finishType" },
  { icon: color, title: "finishColor", id: "finishColor" },
  { icon: color, title: "peephole", id: "peephole" },
  { icon: parameters, title: "dimensions", id: "dimensions" },
  { icon: handlesColor, title: "handlesColor", id: "handlesColor" },
  { icon: locks, title: "locks", id: "locks" },
  { icon: color, title: "metalColor", id: "metalColor" },
  { icon: color, title: "threshold", id: "threshold" },
  { icon: casingColor, title: "casingColor", id: "casingColor" },
  { icon: loops, title: "opening side", id: "openingSide" },
  // { icon: loops, title: "loops color", id: "loopsColor" },
  { icon: information, title: "information", id: "information" },

  // { icon: parameters, title: "Параметры", id: "parameters" },
  // { icon: handles, title: "Ручки", id: "handles" },
  // { icon: accessories, title: "Аксессуары", id: "accessories" },
];
