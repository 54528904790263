import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import shareIcon from "assets/img/header/shareIcon.svg";
import { saveConfig } from "modules/menu/redux";
import {
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  VKShareButton,
  VKIcon,
  ViberShareButton,
  ViberIcon,
} from "react-share";
import s from "./index.module.scss";

export const SocialShare = () => {
  const dispatch = useDispatch();
  const title = "Torex конфигуратор";
  const [isOpen, setIsOpen] = useState(false);
  const configurationId = useSelector((state) => state.configurator.configurationId);
  let location = window.location.href;

  if (location.indexOf("?") !== -1) {
    location = location.slice(0, location.indexOf("?"));
  }
  const url = `${location}?configurationId=${configurationId}`;

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    dispatch(saveConfig(configurationId));
  };

  useEffect(() => {
    document.addEventListener("click", () => setIsOpen(false));

    return function cleanup() {
      document.removeEventListener("click", () => setIsOpen(false));
    };
  }, []);

  return (
    <div className={s.dropdown__container} onClick={(e) => toggleDropdown(e)}>
      <div className={s.dropdown__toggle}>
        <img alt="share" src={shareIcon} />
      </div>

      {isOpen && (
        <div className={s.dropdown__content}>
          <div className={s.social__button__wrapper}>
            <TelegramShareButton
              url={url}
              className={s.social__button}
              title={title}
              image="https://www.торэксдверь.рф/upload/ammina.optimizer/jpg/q80/upload/resize_cache/iblock/714/500_500_140cd750bba9870f18aada2478b24840a/fayl_dveri_protivopozharnoy_delta_m_10_kongo_venge_d22_pvkh_belyy_shambori_asmarket.jpg">
              <TelegramIcon size="20" borderRadius="50%" bgStyle={{ fill: "#656565" }} />{" "}
              <p>Telegram</p>
            </TelegramShareButton>
          </div>

          <div className={s.social__button__wrapper}>
            <WhatsappShareButton
              url={url}
              className={s.social__button}
              title={title}
              image="https://www.торэксдверь.рф/upload/ammina.optimizer/jpg/q80/upload/resize_cache/iblock/714/500_500_140cd750bba9870f18aada2478b24840a/fayl_dveri_protivopozharnoy_delta_m_10_kongo_venge_d22_pvkh_belyy_shambori_asmarket.jpg">
              <WhatsappIcon size="20" borderRadius="50%" bgStyle={{ fill: "#656565" }} />{" "}
              <p>WhatsApp</p>
            </WhatsappShareButton>
          </div>

          <div className={s.social__button__wrapper}>
            <VKShareButton
              url={url}
              className={s.social__button}
              title={title}
              image="https://www.торэксдверь.рф/upload/ammina.optimizer/jpg/q80/upload/resize_cache/iblock/714/500_500_140cd750bba9870f18aada2478b24840a/fayl_dveri_protivopozharnoy_delta_m_10_kongo_venge_d22_pvkh_belyy_shambori_asmarket.jpg">
              <VKIcon size="20" borderRadius="50%" bgStyle={{ fill: "#656565" }} /> <p>Vkontakte</p>
            </VKShareButton>
          </div>

          <div className={s.social__button__wrapper}>
            <ViberShareButton url={url} className={s.social__button} title={title}>
              <ViberIcon size="20" borderRadius="50%" bgStyle={{ fill: "#656565" }} /> <p>Viber</p>
            </ViberShareButton>
          </div>
        </div>
      )}
    </div>
  );
};
