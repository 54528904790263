import Model from "modules/menu/components/model";
import FinishType from "modules/menu/components/finishType";
import FinishColor from "modules/menu/components/finishColor";
import MetalColor from "modules/menu/components/metalColor";
import CasingColor from "modules/menu/components/casingColor";
import HandlesColor from "modules/menu/components/handlesColor";
import Locks from "modules/menu/components/locks";
import Information from "modules/menu/components/information";
import ThresholdColor from "modules/menu/components/thresholdColor";
import LoopsColor from "modules/menu/components/loopsColor";
import Peephole from "modules/menu/components/peephole";
import Dimensions from "modules/menu/components/dimensions";
import Type from "modules/menu/components/type";
import Style from "modules/menu/components/style";
import OpeningSide from "modules/menu/components/openingSide";

import model from "assets/img/sidebar/model.svg";
import parameters from "assets/img/sidebar/parameters.svg";
import finishType from "assets/img/sidebar/finishType.svg";
import color from "assets/img/sidebar/color.svg";
import casingColor from "assets/img/sidebar/casingColor.svg";
import handlesColor from "assets/img/sidebar/handlesColor.svg";
import locks from "assets/img/sidebar/locks.svg";
import loops from "assets/img/sidebar/loops.svg";
import information from "assets/img/sidebar/information.svg";

export const sidebarContents = [
  {
    id: "type",
    component: <Type />,
    title: "door type",
    icon: color,
  },
  {
    id: "style",
    component: <Style />,
    icon: color,
    title: "style",
  },
  {
    id: "model",
    component: <Model />,
    icon: model,
    title: "model",
  },
  {
    id: "finishType",
    component: <FinishType />,
    icon: finishType,
    title: "finishType",
  },
  {
    id: "finishColor",
    component: <FinishColor />,
    icon: color,
    title: "finishColor",
  },
  {
    id: "peephole",
    component: <Peephole />,
    icon: color,
    title: "peephole",
  },
  {
    id: "dimensions",
    component: <Dimensions />,
    icon: parameters,
    title: "dimensions",
  },

  {
    id: "metalColor",
    component: <MetalColor />,
    icon: color,
    title: "metalColor",
  },
  {
    id: "casingColor",
    component: <CasingColor />,
    icon: casingColor,
    title: "casingColor",
  },
  {
    id: "handlesColor",
    component: <HandlesColor />,
    icon: handlesColor,
    title: "handlesColor",
  },
  {
    id: "locks",
    component: <Locks />,
    icon: locks,
    title: "locks",
  },
  {
    id: "openingSide",
    component: <OpeningSide />,
    icon: loops,
    title: "opening side",
  },
  // {
  //   id: "loopsColor",
  //   component: <LoopsColor />,
  //   icon: loops,
  //   title: "loops color",
  // },
  {
    id: "threshold",
    component: <ThresholdColor />,
    icon: color,
    title: "threshold",
  },
  {
    id: "information",
    component: <Information />,
    icon: information,
    title: "information",
  },
];
