import React from "react";
import closeIcon from "assets/img/common/close.svg";
import successIcon from "assets/img/common/successIcon.png";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

export const ModalSuccess = ({ hideModal }) => {
  const { t } = useTranslation();

  const hideAllModal = (e) => {
    if (e.target.id === "ModalBackdrop") hideModal();
  };
  return (
    <div className={s.backdrop} onClick={hideAllModal} id="ModalBackdrop">
      <div className={s.container__scroll}>
        <div className={s.container__flex} onClick={hideModal}>
          <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <div className={s.button__close} onClick={hideModal}>
              <img src={closeIcon} alt="close__icon" />
            </div>

            <p className={s.success__text}>{t("application successfully sent")}</p>
            <img className={s.success__icon} src={successIcon} alt="success-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
