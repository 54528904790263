import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "api/index";
import axios from "axios";

export const createConfig = createAsyncThunk(
  "configurator/createConfig",
  async function (requestBody, thunkAPI) {
    const response = await axios.post(`${baseURL}/api/v1/configuration`, requestBody);
    // console.log("createConfig res", response.data);

    return response.data;
  }
);

export const saveConfig = createAsyncThunk(
  "configurator/saveConfig",
  async function (configurationId, thunkAPI) {
    const response = await axios.put(`${baseURL}/api/v1/configuration/shared/${configurationId}`);
    // console.log("createConfig res", response.data);

    return response.data;
  }
);

export const sendOrder = createAsyncThunk(
  "configurator/sendOrder",
  async function ({ userId, body }, thunkAPI) {
    const response = await axios.post(
      `${baseURL}/api/v1/configuration/create-application/${userId}`,
      body
    );
    // console.log("sendOrder", response);

    return response.data;
  }
);

const configuratorSlice = createSlice({
  name: "configurator",
  initialState: {
    insideDoorPath: "",
    outsideDoorPath: "",
    loading: true,
    totalCost: 0,

    userId: null,
    configurationId: null,
    models: [],
    modelId: null,
    types: [],
    selectedTypeId: null,
    styles: [],
    selectedStyleId: null,

    outsideSlabs: [],
    outsideSlabId: null,
    insideSlabs: [],
    insideSlabId: null,

    outsideSlabColors: [],
    outsideSlabColorId: null,
    insideSlabColors: [],
    insideSlabColorId: null,

    outsideHardwareColors: [],
    outsideHardwareColorId: null,
    insideHardwareColors: [],
    insideHardwareColorId: null,

    outsideFrameColors: [],
    outsideFrameColorId: null,
    insideFrameColors: [],
    insideFrameColorId: null,

    outsideThresholdColors: [],
    outsideThresholdColorId: null,
    insideThresholdColors: [],
    insideThresholdColorId: null,

    locks: [],
    lockId: null,
    additionalLocks: [],
    additionalLockId: null,

    hingesColors: [],
    hingesColorId: null,

    casings: [],
    casingColorId: null,

    peepholeTypes: [],
    peepholeTypeId: null,

    heights: [],
    heightId: null,
    widths: [],
    widthId: null,
    leftOpening: true,

    backgroundFloorLayers: [],
    outsideBackgroundFloorLayerId: null,
    insideBackgroundFloorLayerId: null,
    backgroundWallLayers: [],
    outsideBackgroundWallLayerId: null,
    insideBackgroundWallLayerId: null,

    isConfiguratorReseted: false
  },

  reducers: {
    resetConfigurator(state, action) {
      state.userId = null;
      state.configurationId = null;
      state.modelId = null;
      state.selectedTypeId = null;
      state.selectedStyleId = null;
      state.outsideSlabId = null;
      state.insideSlabId = null;
      state.outsideSlabColorId = null;
      state.insideSlabColorId = null;
      state.outsideHardwareColorId = null;
      state.insideHardwareColorId = null;
      state.outsideFrameColorId = null;
      state.insideFrameColorId = null;
      state.outsideThresholdColorId = null;
      state.insideThresholdColorId = null;
      state.lockId = null;
      state.additionalLockId = null;
      state.hingesColorId = null;
      state.casingColorId = null;
      state.peepholeTypeId = null;
      state.totalCost = 0;
      state.heightId = null;
      state.widthId = null;
      state.leftOpening = true;

      state.outsideBackgroundFloorLayerId = null;
      state.insideBackgroundFloorLayerId = null;
      state.outsideBackgroundWallLayerId = null;
      state.insideBackgroundWallLayerId = null;
      state.isConfiguratorReseted = true;
    },

    setModelIdConfig(state, action) {
      state.modelId = action.payload;
    },
    setSelectedTypeId(state, action) {
      state.selectedTypeId = action.payload;
    },

    setSelectedStyleId(state, action) {
      state.selectedStyleId = action.payload;
    },

    setOutsideSlabId(state, action) {
      state.outsideSlabId = action.payload;
    },

    setInsideSlabId(state, action) {
      state.insideSlabId = action.payload;
    },

    setOutsideSlabColorId(state, action) {
      state.outsideSlabColorId = action.payload;
    },
    setInsideSlabColorId(state, action) {
      state.insideSlabColorId = action.payload;
    },

    setOutsideHardwareColorId(state, action) {
      state.outsideHardwareColorId = action.payload;
    },
    setInsideHandleColorId(state, action) {
      state.insideHardwareColorId = action.payload;
    },

    setOutsideFrameColorId(state, action) {
      state.outsideFrameColorId = action.payload;
    },
    setInsideFrameColorId(state, action) {
      state.insideFrameColorId = action.payload;
    },

    setOutsideThresholdColorId(state, action) {
      state.outsideThresholdColorId = action.payload;
    },
    setInsideThresholdColorId(state, action) {
      state.insideThresholdColorId = action.payload;
    },

    setLockId(state, action) {
      state.lockId = action.payload;
    },

    setAdditionalLockId(state, action) {
      state.additionalLockId = action.payload;
    },

    setHingesColorId(state, action) {
      state.hingesColorId = action.payload;
    },
    setCasingColorId(state, action) {
      state.casingColorId = action.payload;
    },

    setPeepholeTypeId(state, action) {
      state.peepholeTypeId = action.payload;
    },

    setHeightId(state, action) {
      state.heightId = action.payload;
    },

    setWidthId(state, action) {
      state.widthId = action.payload;
    },

    setLeftOpening(state, action) {
      state.leftOpening = action.payload;
    },

    setOutsideBackgroundFloorLayerId(state, action) {
      state.outsideBackgroundFloorLayerId = action.payload;
    },
    setInsideBackgroundFloorLayerId(state, action) {
      state.insideBackgroundFloorLayerId = action.payload;
    },
    setOutsideBackgroundWallLayerId(state, action) {
      state.outsideBackgroundWallLayerId = action.payload;
    },
    setInsideBackgroundWallLayerId(state, action) {
      state.insideBackgroundWallLayerId = action.payload;
    },
  },

  extraReducers: {
    [createConfig.fulfilled]: (state, action) => {
      // console.log(" configurationId redux ", action.payload?.configurationId);
      localStorage.setItem("LSuserId", action.payload?.userId);

      state.insideDoorPath = action.payload?.insideDoorPath;
      state.outsideDoorPath = action.payload?.outsideDoorPath;
      state.loading = false;
      state.isConfiguratorReseted = false;
      state.userId = action.payload?.userId;
      state.configurationId = action.payload?.configurationId;
      state.totalCost = action.payload?.totalCost;

      state.models = action.payload?.models;
      state.modelId = action.payload?.selectedModelId;
      state.types = action.payload?.types;
      state.selectedTypeId = action.payload?.selectedTypeId;
      state.styles = action.payload?.styles;
      state.selectedStyleId = action.payload?.selectedStyleId;

      state.outsideSlabs = action.payload?.outsideSlabs;
      state.outsideSlabId = action.payload?.selectedOutsideSlabId;
      state.insideSlabs = action.payload?.insideSlabs;
      state.insideSlabId = action.payload?.selectedInsideSlabId;

      state.outsideSlabColors = action.payload?.outsideSlabColors;
      state.outsideSlabColorId = action.payload?.selectedOutsideSlabColorId;
      state.insideSlabColors = action.payload?.insideSlabColors;
      state.insideSlabColorId = action.payload?.selectedInsideSlabColorId;

      state.outsideHardwareColors = action.payload?.outsideHardwareColors;
      state.outsideHardwareColorId = action.payload?.selectedOutsideHardwareColorId;
      state.insideHardwareColors = action.payload?.insideHardwareColors;
      state.insideHardwareColorId = action.payload?.selectedInsideHardwareColorId;

      state.outsideFrameColors = action.payload?.outsideFrameColors;
      state.outsideFrameColorId = action.payload?.selectedOutsideFrameColorId;
      state.insideFrameColors = action.payload?.insideFrameColors;
      state.insideFrameColorId = action.payload?.selectedInsideFrameColorId;

      state.outsideThresholdColors = action.payload?.outsideThresholdColors;
      state.outsideThresholdColorId = action.payload?.selectedOutsideThresholdColorId;
      state.insideThresholdColors = action.payload?.insideThresholdColors;
      state.insideThresholdColorId = action.payload?.selectedInsideThresholdColorId;

      state.locks = action.payload?.locks;
      state.lockId = action.payload?.selectedLockId;
      state.additionalLocks = action.payload?.additionalLocks;
      state.additionalLockId = action.payload?.selectedAdditionalLockId;

      state.hingesColors = action.payload?.hingesColors;
      state.hingesColorId = action.payload?.selectedHingesColorId;

      state.casings = action.payload?.casings;
      state.casingColorId = action.payload?.selectedCasingColorId;

      state.peepholeTypes = action.payload?.peepholeTypes;
      state.peepholeTypeId = action.payload?.peepholeTypeId;

      state.heights = action.payload?.heights;
      state.heightId = action.payload?.selectedHeightId;
      state.widths = action.payload?.widths;
      state.widthId = action.payload?.selectedWidthId;

      state.backgroundFloorLayers = action.payload?.backgroundFloorLayers;
      state.outsideBackgroundFloorLayerId = action.payload?.selectedOutsideBackgroundFloorLayerId;
      state.insideBackgroundFloorLayerId = action.payload?.selectedInsideBackgroundFloorLayerId;
      state.backgroundWallLayers = action.payload?.backgroundWallLayers;
      state.outsideBackgroundWallLayerId = action.payload?.selectedOutsideBackgroundWallLayerId;
      state.insideBackgroundWallLayerId = action.payload?.selectedInsideBackgroundWallLayerId;
    },

    [createConfig.pending]: (state, action) => {
      state.loading = true;
    },

    [createConfig.rejected]: (state, action) => {
      state.loading = false;
      state.isConfiguratorReseted = false;
    },
  },
});

export const {
  resetConfigurator,
  setModelIdConfig,
  setSelectedTypeId,
  setSelectedStyleId,
  setOutsideSlabId,
  setInsideSlabId,
  setOutsideSlabColorId,
  setInsideSlabColorId,
  setOutsideHardwareColorId,
  setInsideHardwareColorId,
  setOutsideFrameColorId,
  setInsideFrameColorId,
  setOutsideThresholdColorId,
  setInsideThresholdColorId,
  setLockId,
  setAdditionalLockId,
  setHingesColorId,
  setCasingColorId,
  setPeepholeTypeId,
  setHeightId,
  setWidthId,
  setLeftOpening,
  setOutsideBackgroundFloorLayerId,
  setInsideBackgroundFloorLayerId,
  setOutsideBackgroundWallLayerId,
  setInsideBackgroundWallLayerId,
} = configuratorSlice.actions;

export default configuratorSlice.reducer;
