import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { SidebarTrigger } from "components/sidebar/sidebarTrigger";
import { sidebarContents } from "modules/sidebarConstructor/constants/sidebarContents";
import s from "./index.module.scss";

export const MobileFilters = () => {
  const [count, setCount] = useState(0);

  const handleSetCount = (id) => {
    if (id === count) {
      setCount(0);
    } else {
      setCount(id);
    }
  };

  return (
    <div>
      {sidebarContents.map((sidebarData) => {
        return (
          <Collapsible
            key={sidebarData.id}
            open={count === sidebarData.id}
            handleTriggerClick={() => handleSetCount(sidebarData.id)}
            trigger={
              <SidebarTrigger
                icon={sidebarData.icon}
                title={sidebarData.title}
                isOpen={count === sidebarData.id}
              />
            }>
            <div className={s.content__wrapper}>{sidebarData.component}</div>
          </Collapsible>
        );
      })}
    </div>
  );
};
