import React, { useRef } from "react";
import { Display } from "components/display";
import Header from "components/header";
import { createConfig } from "modules/menu/redux";
import Sidebar from "modules/sidebarConstructor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { availableLanguages } from "constants";
import { MobileDisplay } from "components/mobileDisplay";
import s from "./App.module.scss";

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [isFirst, setIsFirst] = useState(true);
  const { isTablet } = useWindowDimensions();

  const userId = useSelector((state) => state.configurator.userId);
  // const currentConfigurationId = useSelector((state) => state.configurator.configurationId);

  const modelId = useSelector((state) => state.configurator.modelId);
  const selectedTypeId = useSelector((state) => state.configurator.selectedTypeId);
  const selectedStyleId = useSelector((state) => state.configurator.selectedStyleId);

  const outsideSlabId = useSelector((state) => state.configurator.outsideSlabId);
  const insideSlabId = useSelector((state) => state.configurator.insideSlabId);

  const outsideSlabColorId = useSelector((state) => state.configurator.outsideSlabColorId);
  const insideSlabColorId = useSelector((state) => state.configurator.insideSlabColorId);

  const outsideHardwareColorId = useSelector((state) => state.configurator.outsideHardwareColorId);
  const insideHardwareColorId = useSelector((state) => state.configurator.insideHardwareColorId);

  const outsideFrameColorId = useSelector((state) => state.configurator.outsideFrameColorId);
  const insideFrameColorId = useSelector((state) => state.configurator.insideFrameColorId);

  const outsideThresholdColorId = useSelector(
      (state) => state.configurator.outsideThresholdColorId
  );
  const insideThresholdColorId = useSelector((state) => state.configurator.insideThresholdColorId);

  const lockId = useSelector((state) => state.configurator.lockId);
  const additionalLockId = useSelector((state) => state.configurator.additionalLockId);
  const leftOpening = useSelector((state) => state.configurator.leftOpening);
  const hingesColorId = useSelector((state) => state.configurator.hingesColorId);
  const casingColorId = useSelector((state) => state.configurator.casingColorId);
  const peepholeTypeId = useSelector((state) => state.configurator.peepholeTypeId);

  const heightId = useSelector((state) => state.configurator.heightId);
  const widthId = useSelector((state) => state.configurator.widthId);

  const outsideBackgroundWallLayerId = useSelector(
      (state) => state.configurator.outsideBackgroundWallLayerId
  );
  const insideBackgroundWallLayerId = useSelector(
      (state) => state.configurator.insideBackgroundWallLayerId
  );
  const outsideBackgroundFloorLayerId = useSelector(
      (state) => state.configurator.outsideBackgroundFloorLayerId
  );
  const insideBackgroundFloorLayerId = useSelector(
      (state) => state.configurator.insideBackgroundFloorLayerId
  );

  const isConfiguratorLoading = useSelector(
      (state) => state.configurator.loading
  );

  const requestBody = {
    userId,
    useLiteVersionImage: true,
    language: availableLanguages[i18n.language],
    typeId: selectedTypeId,
    styleId: selectedStyleId,
    modelId,

    outsideSlabId,
    insideSlabId,

    outsideSlabColorId,
    insideSlabColorId,

    outsideHardwareColorId,
    insideHardwareColorId,

    outsideFrameColorId,
    insideFrameColorId,

    outsideThresholdColorId,
    insideThresholdColorId,

    lockId,
    additionalLockId,
    casingColorId,
    hingesColorId,
    peepholeTypeId,

    heightId,
    widthId,
    leftOpening,

    outsideBackgroundWallLayerId,
    insideBackgroundWallLayerId,
    outsideBackgroundFloorLayerId,
    insideBackgroundFloorLayerId,
  };

  const depsArr = Object.values(requestBody);

  let prevDepsArr = useRef(depsArr);

  useEffect(() => {
    const LSuserId = localStorage.getItem("LSuserId");

    const location = window.location.href;
    const match = location.indexOf("=");
    if (match !== -1) {
      const configurationId = location.slice(match + 1);
      requestBody.configurationId = configurationId;
    }

    if (match === -1 && LSuserId && isFirst) {
      requestBody.userId = LSuserId;
    }

    dispatch(createConfig(requestBody));
    setIsFirst(false);
  }, []);

  useEffect(() => {
    if (!isConfiguratorLoading) {
      prevDepsArr.current = depsArr;
    }
  }, [isConfiguratorLoading]);


  useEffect(() => {
    if (!depsArr.includes(null) && !prevDepsArr.current.includes(null)) {
      const isDepsEqual = JSON.stringify(depsArr) === JSON.stringify(prevDepsArr.current);

      if (!isDepsEqual) {
        dispatch(createConfig(requestBody));
      }

      prevDepsArr.current = depsArr;
    }
  }, [depsArr]);

  return (
      <div className="App">
        <Header />
        {!isTablet ? (
            <div>
              <MobileDisplay />
            </div>
        ) : (
            <div className={s.middle}>
              <Sidebar />
              <Display />
            </div>
        )}
      </div>
  );
}

export default App;
