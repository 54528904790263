import React from "react";
import { TextFormik } from "components/form/fields/textFormik";
import { PhoneFormik } from "../fields/phoneFormik";
import { useDispatch, useSelector } from "react-redux";
import { sendOrder } from "modules/menu/redux";
import { FormContainer } from "components/form/formContainer";
import { orderValidationSchema } from "utils/validation";
import { TextAreaFormik } from "../fields/textAreaFormik";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

export const OrderForm = ({ hideModal, showModalSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userId = useSelector((state) => state.configurator.userId);

  const initialValues = {
    userName: "",
    phone: "",
    city: "",
    email: "",
    comment: "",
  };

  const onSubmit = (values) => {
    // console.log("OrderForm ", values);
    const body = {
      name: values.userName,
      phone: values.phone,
      city: values.city,
      email: values.email,
      comment: values.comment,
    };

    dispatch(sendOrder({ userId, body }));
    hideModal();
    showModalSuccess();
  };

  return (
    <div className={s.form__container}>
      <h5 className={s.form__title}>{t("contacts")}</h5>

      <FormContainer
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={orderValidationSchema}
        onSubmit={onSubmit}>
        {({ errors }) => (
          <>
            <div className={s.input__wrapper}>
              <TextFormik
                name="userName"
                placeholder={t("your name")}
                customStyle={errors.userName && s.input__error}
              />
              {!!errors.userName && <p className={s.input__error__text}>{errors.userName}</p>}
            </div>

            <div className={s.input__wrapper}>
              <TextFormik
                name="city"
                placeholder={t("city")}
                customStyle={errors.city && s.input__error}
              />
              {!!errors.city && <p className={s.input__error__text}>{errors.city}</p>}
            </div>

            <div className={s.input__wrapper}>
              <PhoneFormik
                name="phone"
                placeholder={t("phone")}
                customStyle={errors.phone && s.input__error}
              />
              {!!errors.phone && <p className={s.input__error__text}>{errors.phone}</p>}
            </div>

            <div className={s.input__wrapper}>
              <TextFormik name="email" placeholder="E-mail" />
              {!!errors.email && <p className={s.input__error__text}>{errors.email}</p>}
            </div>

            <div className={s.input__comment__wrapper}>
              <TextAreaFormik name="comment" placeholder={t("comment")} />
            </div>

            <button className={s.button__submit} type="submit">
              {t("ask price")}
            </button>

            <div className={s.policy}>
              <p>{t("by clicking on the button you agree")}</p>
              <a href="/">{t("with privacy policy")}</a>
            </div>
          </>
        )}
      </FormContainer>
    </div>
  );
};
