import React, { useState } from "react";
import closeIcon from "assets/img/common/close.svg";
import checkIcon from "assets/img/common/check.svg";
import colorEmpty from "assets/img/common/color-empty.png";
import { baseURL } from "api";
import { useSelector, useDispatch } from "react-redux";
import {
  setInsideBackgroundWallLayerId,
  setOutsideBackgroundWallLayerId,
} from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import s from "./index.module.scss";

export const Wall = ({ setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationTab, setLocationTab] = useState("outside");
  const loading = useSelector((state) => state.configurator.loading);
  const backgroundWallLayers = useSelector((state) => state.configurator.backgroundWallLayers);

  const outsideBackgroundWallLayerId = useSelector(
    (state) => state.configurator.outsideBackgroundWallLayerId
  );
  const insideBackgroundWallLayerId = useSelector(
    (state) => state.configurator.insideBackgroundWallLayerId
  );

  const handleSetOutsideBackgroundWallId = (item) => {
    if (!loading && !item?.locked) dispatch(setOutsideBackgroundWallLayerId(item.id));
  };

  const handleSetInsideBackgroundWallId = (item) => {
    if (!loading && !item?.locked) dispatch(setInsideBackgroundWallLayerId(item.id));
  };

  return (
    <div className={s.container}>
      <div className={s.wall__heading}>
        <h4 className={s.wall__title}>{t("walls color")}</h4>

        <img
          src={closeIcon}
          alt="close__icon"
          className={s.close__icon}
          onClick={() => setOpen(false)}
        />
      </div>

      <div className={s.tab__list}>
        <p
          onClick={() => setLocationTab("outside")}
          className={clsx(s.tab__item, locationTab === "outside" && s.active)}>
          {t("outside")}
        </p>
        <p
          onClick={() => setLocationTab("inside")}
          className={clsx(s.tab__item, locationTab === "inside" && s.active)}>
          {t("inside")}
        </p>
      </div>

      <div className={s.tab__content}>
        {locationTab === "outside" && (
          <div className={s.color__list}>
            {!!backgroundWallLayers.length &&
              backgroundWallLayers.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={s.color__item}
                    onClick={() => handleSetOutsideBackgroundWallId(item)}>
                    <div className={clsx(s.color__img, item?.locked && s.locked)}>
                      {outsideBackgroundWallLayerId === item.id && (
                        <img className={s.check} src={checkIcon} alt="" />
                      )}

                      <img
                        className={clsx(s.color__image, item?.locked && s.locked)}
                        src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                        alt="type"
                      />
                    </div>
                    <p className={s.color__title}>{item.name}</p>
                  </div>
                );
              })}
          </div>
        )}

        {locationTab === "inside" && (
          <div className={s.color__list}>
            {!!backgroundWallLayers.length &&
              backgroundWallLayers.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={s.color__item}
                    onClick={() => handleSetInsideBackgroundWallId(item)}>
                    <div className={clsx(s.color__img, item?.locked && s.locked)}>
                      {insideBackgroundWallLayerId === item.id && (
                        <img className={s.check} src={checkIcon} alt="" />
                      )}

                      <img
                        className={clsx(s.color__image, item?.locked && s.locked)}
                        src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                        alt="type"
                      />
                    </div>
                    <p className={s.color__title}>{item.name}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
