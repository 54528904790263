import React from "react";
import colorEmpty from "assets/img/common/color.png";
import checkIcon from "assets/img/common/check.svg";
import { baseURL } from "api";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedStyleId } from "modules/menu/redux";
import clsx from "clsx";
import s from "./index.module.scss";

const Style = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.configurator.loading);
  const styles = useSelector((state) => state.configurator.styles);
  const selectedStyleId = useSelector((state) => state.configurator.selectedStyleId);

  const handleSetStyle = (item) => {
    if (!loading && !item?.locked) dispatch(setSelectedStyleId(item.id));
  };

  return (
    <div className={s.container}>
      <div className={s.inner}>
        <h4 className={s.title}>{t("style")}</h4>

        <div className={s.style__list}>
          {!!styles.length &&
            styles.map((item) => {
              return (
                <div key={item.id} className={s.style__item} onClick={() => handleSetStyle(item)}>
                  <div className={clsx(s.style__img, item?.locked && s.locked)}>
                    {selectedStyleId === item.id && (
                      <img className={s.check} src={checkIcon} alt="" />
                    )}

                    <img
                      className={clsx(s.style__image, item?.locked && s.locked)}
                      src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                      alt="loop"
                    />
                  </div>
                  <p className={s.style__title}>{item.name}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Style;
