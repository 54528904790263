import colorEmpty from "assets/img/common/color.png";
import checkIcon from "assets/img/common/check.svg";
import closeIcon from "assets/img/common/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "api";
import { setAdditionalLockId, setLockId } from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import s from "./index.module.scss";

const Locks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.configurator.loading);
  const locks = useSelector((state) => state.configurator.locks);
  const lockId = useSelector((state) => state.configurator.lockId);

  const additionalLocks = useSelector((state) => state.configurator.additionalLocks);
  const additionalLockId = useSelector((state) => state.configurator.additionalLockId);

  const handleSetLockId = (item) => {
    if (!loading && !item?.locked) dispatch(setLockId(item.id));
  };

  const handleSetAdditionalLockId = (item) => {
    if (loading && item?.locked) {
      return;
    }

    if (item.id === additionalLockId) {
      dispatch(setAdditionalLockId(""));
    } else {
      dispatch(setAdditionalLockId(item.id));
    }
  };

  const handleResetAdditionalLockId = () => {
    if (!loading) {
      dispatch(setAdditionalLockId(""));
    }
  };

  return (
    <div className={s.container}>
      <div className={s.locks__inner}>
        <h4 className={s.locks__title}>{t("locks")}</h4>

        <div className={s.lock__list}>
          {!!locks.length &&
            locks.map((item) => {
              return (
                <div key={item.id} className={s.lock__item} onClick={() => handleSetLockId(item)}>
                  <div className={clsx(s.lock__img, item?.locked && s.locked)}>
                    {lockId === item.id && <img className={s.check} src={checkIcon} alt="" />}

                    <img
                      className={clsx(s.lock__image, item?.locked && s.locked)}
                      src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                      alt="lock"
                    />
                  </div>
                  <p className={s.lock__title}>{item.name}</p>
                </div>
              );
            })}
        </div>

        {!!additionalLocks.length && (
          <div className={s.additional__locks}>
            <h4 className={s.additional__locks__title}>{t("additional locks")}</h4>
            {additionalLockId && (
              <button className={s.button__reset} onClick={handleResetAdditionalLockId}>
                <span>исключить доп замок</span>
                <img src={closeIcon} alt="close__icon" />
              </button>
            )}

            <div className={s.lock__list}>
              {additionalLocks.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={s.lock__item}
                    onClick={() => handleSetAdditionalLockId(item)}>
                    <div className={clsx(s.lock__img, item?.locked && s.locked)}>
                      {additionalLockId === item.id && (
                        <img className={s.check} src={checkIcon} alt="" />
                      )}

                      <img
                        className={clsx(s.lock__image, item?.locked && s.locked)}
                        src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                        alt="lock"
                      />
                    </div>
                    <p className={s.lock__title}>{item.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Locks;
