import { useTranslation } from "react-i18next";
import "./index.scss";

const SidebarTab = (props) => {
  const { icon, title, onClick } = props;
  const { t } = useTranslation();

  return (
    <button onClick={onClick} className="sidetab">
      <img alt="icon" className="sidetab__icon" src={icon} />
      <span className="sidetab__title">{t(title)}</span>
    </button>
  );
};

export default SidebarTab;
