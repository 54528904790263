import React from "react";
import downloadIcon from "assets/img/header/downloadIcon.svg";
import { useTranslation } from "react-i18next";
import { baseURL } from "api";
import { useSelector } from "react-redux";
import axios from "axios";
import s from "./index.module.scss";

export const DownloadButton = () => {
  const { t } = useTranslation();
  const userId = useSelector((state) => state.configurator.userId);

  const handleDownload = async () => {
    if (!userId) {
      return;
    }

    try {
      const response = await axios.get(`${baseURL}/api/v1/configuration/pdf/${userId}`);
      if (response?.data?.path) {
        const url = `${baseURL}${response?.data?.path}`;

        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "doc123.pdf";
        anchor.target = "blank";
        document.body.append(anchor);
        anchor.style = "display: none";
        anchor.click();
        anchor.remove();
      }
    } catch (error) {
      // console.log("handleDownload", error);
    }
  };

  return (
    <button className={s.button__download} onClick={handleDownload}>
      <span className={s.button__download_text}>{t("download")} PDF</span>
      <img alt="dwnld" className={s.button__download_icon} src={downloadIcon} />
    </button>
  );
};
