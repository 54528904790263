import React, { useState } from "react";
import brush from "assets/img/common/brush.svg";
import flip from "assets/img/common/flip.svg";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";
import clsx from "clsx";
import s from "./index.module.scss";

export const SwipeMenu = ({
  setWallMenuOpen,
  setFloorMenuOpen,
  handleSetLocationTab,
  locationTab,
}) => {
  const { t } = useTranslation();
  const [swipeMenu, setSwipeMenu] = useState(true);

  const handlers = useSwipeable({
    onSwipedRight: () => setSwipeMenu(false),
    onSwipedLeft: () => setSwipeMenu(true),
  });

  return (
    <div className={clsx(s.container, !swipeMenu && s.hidden)} {...handlers}>
      <div onClick={() => setSwipeMenu(!swipeMenu)} className={s.button__swipe}></div>

      <div className={s.buttons}>
        <button className={s.button__background} onClick={() => setWallMenuOpen(true)}>
          <img src={brush} alt="walls" />
          <p className={s.button__background__text}>{t("walls")}</p>
        </button>
        <button className={s.button__background} onClick={() => setFloorMenuOpen(true)}>
          <img src={brush} alt="floor" />
          <p className={s.button__background__text}>{t("floor")}</p>
        </button>
        <button className={clsx(s.button__background, s.button__location)} onClick={handleSetLocationTab}>
          <img src={flip} alt="flip" />
          <p className={s.button__background__text}>
            {locationTab === "outside" ? t("inside") : t("outside")}
          </p>
        </button>
      </div>
    </div>
  );
};
