import SidebarTab from "components/sidebar/sidebarTab";
import SidebarContent from "components/sidebar/sidebarContent";

import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebarId } from "./redux";

import { sidebarContents } from "./constants/sidebarContents";
import { sidebarTabs } from "./constants/sidebarTabs";

import "./index.scss";

const Sidebar = () => {
  const dispatch = useDispatch();

  const activeSidebarId = useSelector((state) => state.sidebarConstructor.activeSidebarId);
  const setSidebarId = (activeSidebarId) => dispatch(setActiveSidebarId({ activeSidebarId }));

  return (
    <aside className="sidebar">
      <div className="sidetabs">
        {sidebarTabs.map((tab) => (
          <SidebarTab
            onClick={() => setSidebarId(tab.id)}
            key={tab.title}
            icon={tab.icon}
            title={tab.title}
          />
        ))}
      </div>

      {sidebarContents.map(
        (content) =>
          content.id === activeSidebarId && (
            <SidebarContent key={content.id} title={content.title}>
              {content.component}
            </SidebarContent>
          )
      )}
    </aside>
  );
};

export default Sidebar;
