import React, { useState } from "react";
import { baseURL } from "api";
import circularArrow from "assets/img/common/circularArrow.svg";
import filters from "assets/img/common/filters.svg";
import { useSelector, useDispatch } from "react-redux";
import {createConfig, resetConfigurator} from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import { Wall } from "modules/menu/components/wall";
import { useModal } from "hooks/useModal";
import { modals } from "components/modal";
import { Floor } from "modules/menu/components/floor";
import { DisplayLoader } from "components/loader/displayLoader";
import { useEffect } from "react";
import { SwipeMenu } from "./swipeMenu";
import clsx from "clsx";
import s from "./index.module.scss";
import {availableLanguages} from "constants";
import i18n from "i18next";

export const MobileDisplay = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const insideDoorPath = useSelector((state) => state.configurator.insideDoorPath);
  const outsideDoorPath = useSelector((state) => state.configurator.outsideDoorPath);
  const loading = useSelector((state) => state.configurator.loading);
  const [insideDoorLoaded, setInsideDoorLoaded] = useState(false);
  const totalCost = useSelector((state) => state.configurator.totalCost);
  const [wallMenuOpen, setWallMenuOpen] = useState(false);
  const [floorMenuOpen, setFloorMenuOpen] = useState(false);
  const [locationTab, setLocationTab] = useState("outside");
  const { showModal, hideModal } = useModal();

  const models = useSelector((state) => state.configurator.models);
  const modelId = useSelector((state) => state.configurator.modelId);

  const userId = useSelector((state) => state.configurator.userId);
  // const currentConfigurationId = useSelector((state) => state.configurator.configurationId);
  const selectedTypeId = useSelector((state) => state.configurator.selectedTypeId);
  const selectedStyleId = useSelector((state) => state.configurator.selectedStyleId);

  const outsideSlabId = useSelector((state) => state.configurator.outsideSlabId);
  const insideSlabId = useSelector((state) => state.configurator.insideSlabId);

  const outsideSlabColorId = useSelector((state) => state.configurator.outsideSlabColorId);
  const insideSlabColorId = useSelector((state) => state.configurator.insideSlabColorId);

  const outsideHardwareColorId = useSelector((state) => state.configurator.outsideHardwareColorId);
  const insideHardwareColorId = useSelector((state) => state.configurator.insideHardwareColorId);

  const outsideFrameColorId = useSelector((state) => state.configurator.outsideFrameColorId);
  const insideFrameColorId = useSelector((state) => state.configurator.insideFrameColorId);

  const outsideThresholdColorId = useSelector(
      (state) => state.configurator.outsideThresholdColorId
  );
  const insideThresholdColorId = useSelector((state) => state.configurator.insideThresholdColorId);

  const lockId = useSelector((state) => state.configurator.lockId);
  const additionalLockId = useSelector((state) => state.configurator.additionalLockId);
  const leftOpening = useSelector((state) => state.configurator.leftOpening);
  const hingesColorId = useSelector((state) => state.configurator.hingesColorId);
  const casingColorId = useSelector((state) => state.configurator.casingColorId);
  const peepholeTypeId = useSelector((state) => state.configurator.peepholeTypeId);

  const heightId = useSelector((state) => state.configurator.heightId);
  const widthId = useSelector((state) => state.configurator.widthId);

  const outsideBackgroundWallLayerId = useSelector(
      (state) => state.configurator.outsideBackgroundWallLayerId
  );
  const insideBackgroundWallLayerId = useSelector(
      (state) => state.configurator.insideBackgroundWallLayerId
  );
  const outsideBackgroundFloorLayerId = useSelector(
      (state) => state.configurator.outsideBackgroundFloorLayerId
  );
  const insideBackgroundFloorLayerId = useSelector(
      (state) => state.configurator.insideBackgroundFloorLayerId
  );

  const isConfiguratorReseted = useSelector(
      (state) => state.configurator.isConfiguratorReseted
  );

  const isConfiguratorLoading = useSelector(
      (state) => state.configurator.loading
  );

  const requestBody = {
    userId,
    useLiteVersionImage: true,
    language: availableLanguages[i18n.language],
    typeId: selectedTypeId,
    styleId: selectedStyleId,
    modelId,

    outsideSlabId,
    insideSlabId,

    outsideSlabColorId,
    insideSlabColorId,

    outsideHardwareColorId,
    insideHardwareColorId,

    outsideFrameColorId,
    insideFrameColorId,

    outsideThresholdColorId,
    insideThresholdColorId,

    lockId,
    additionalLockId,
    casingColorId,
    hingesColorId,
    peepholeTypeId,

    heightId,
    widthId,
    leftOpening,

    outsideBackgroundWallLayerId,
    insideBackgroundWallLayerId,
    outsideBackgroundFloorLayerId,
    insideBackgroundFloorLayerId,
  };

  const handleSetLocationTab = () => {
    if (loading) return;

    if (locationTab === "outside") {
      setLocationTab("inside");
    } else {
      setLocationTab("outside");
    }
  };

  const showModalSuccess = () => {
    showModal(modals.ModalSuccess, { showModal, hideModal });
  };

  const showModalOrder = () => {
    showModal(modals.ModalOrder, { showModal, hideModal, showModalSuccess });
  };

  const showModalMobileFilters = () => {
    showModal(modals.ModalMobileFilters, { showModal, hideModal });
  };

  const handleReset = () => {
    dispatch(resetConfigurator());
  };

  useEffect(() => {
    if (loading) setInsideDoorLoaded(false);
  }, [loading]);

  useEffect(() => {
    if (isConfiguratorReseted) {
      dispatch(createConfig(requestBody));
    }
  }, [isConfiguratorReseted]);

  return (
    <div className={s.container}>
      <button className={s.button__reset} onClick={handleReset}>
        <img src={circularArrow} alt="reset" />
        <p className={s.button__reset__text}>{t("reset")}</p>
      </button>

      <SwipeMenu
        setWallMenuOpen={setWallMenuOpen}
        setFloorMenuOpen={setFloorMenuOpen}
        handleSetLocationTab={handleSetLocationTab}
        locationTab={locationTab}
      />

      {wallMenuOpen && (
        <div className={s.wall__menu}>
          <Wall setOpen={setWallMenuOpen} />
        </div>
      )}
      {floorMenuOpen && (
        <div className={s.floor__menu}>
          <Floor setOpen={setFloorMenuOpen} />
        </div>
      )}

      <div className={s.images}>
        <div className={clsx(s.image__container, locationTab === "inside" && s.hidden)}>
          {insideDoorPath && (
            <>
              <DisplayLoader hide={insideDoorLoaded && !loading} />
              <img
                src={`${baseURL}${outsideDoorPath}`}
                alt=""
                onLoad={() => setInsideDoorLoaded(true)}
              />
            </>
          )}
        </div>

        <div className={clsx(s.image__container, locationTab === "outside" && s.hidden)}>
          {outsideDoorPath && (
            <>
              <DisplayLoader hide={insideDoorLoaded && !loading} />
              <img src={`${baseURL}${insideDoorPath}`} alt="" />
            </>
          )}
        </div>
      </div>

      <button onClick={showModalMobileFilters} className={s.button__open__filters}>
        <img src={filters} alt="filters" />
      </button>

      <div className={s.footer__buttons}>
        <p className={s.footer__price}>
          {t("price from")} <span>{totalCost.toLocaleString()}</span> ₽
        </p>
        <button onClick={showModalOrder} className={s.button__order__modal}>
          {t("ask price")}
        </button>
      </div>
    </div>
  );
};
