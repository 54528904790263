import React, { useEffect } from "react";
import useScrollPosition from "hooks/useScroll";
import { useModal } from "hooks/useModal";

export const ModalRoot = () => {
  const { Modal, modalProps, showModal, hideModal } = useModal();
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    if (Modal) {
      document.body.style.position = "fixed";
      document.body.style.top = `${-scrollPosition}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [Modal]);

  return Modal ? <Modal {...modalProps} showModal={showModal} hideModal={hideModal} /> : null;
};
