import React from "react";
import clsx from "clsx";
import { useFormikContext, useField } from "formik";
import InputMask from "react-input-mask";
import s from "./index.module.scss";

export const PhoneFormik = (props) => {
  const {
    name = "",
    type = "text",
    disabled = false,
    onChange,
    customStyle,
    startIcon,
    ...other
  } = props;
  const [field] = useField(name);
  const form = useFormikContext();

  const handleChange = (e) => {
    form.setFieldValue(name, e.target.value);
    if (onChange) onChange(e);
  };

  const inputValue = field.value || "";

  const evalProps = {
    ...field,
    ...other,
    value: inputValue,
    id: name,
    disabled,
    type,
    onChange: handleChange,
  };

  return (
    <div className={s.input__inner}>
      {startIcon && <img src={startIcon} alt="" className={s.icon__start} />}
      <InputMask
        {...evalProps}
        mask="+7 999 999 99 99"
        className={clsx(s.input__text, startIcon && s.with__start_icon, customStyle)}
      />
    </div>
  );
};
