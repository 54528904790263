import { useTranslation } from "react-i18next";
import s from "./index.module.scss";
import { InfoContent } from "components/infoContent";

const Information = () => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <div className={s.info__inner}>
        <h4 className={s.info__title}>{t("your configuration")}</h4>

        <InfoContent />
      </div>
    </div>
  );
};

export default Information;
