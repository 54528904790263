import { MobileFilters } from "components/mobileFilters";
import React from "react";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

export const ModalMobileFilters = ({ hideModal }) => {
  const { t } = useTranslation();
  const hideAllModal = (e) => {
    if (e.target.id === "ModalBackdrop") hideModal();
  };
  return (
    <div className={s.backdrop} onClick={hideAllModal} id="ModalBackdrop">
      <div className={s.container__scroll}>
        <div className={s.container__flex} onClick={hideModal}>
          <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <MobileFilters />

            <button onClick={hideModal} className={s.button__close}>
              {t("see result")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
