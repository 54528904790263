import React from "react";
import { OrderForm } from "components/form/orderForm";
import Collapsible from "react-collapsible";
import closeIcon from "assets/img/common/close.svg";
import { InfoContent } from "components/infoContent";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import s from "./index.module.scss";

export const ModalOrder = ({ hideModal, showModalSuccess }) => {
  const { t } = useTranslation();

  const hideAllModal = (e) => {
    if (e.target.id === "ModalBackdrop") hideModal();
  };
  return (
    <div className={s.backdrop} onClick={hideAllModal} id="ModalBackdrop">
      <div className={s.container__scroll}>
        <div className={s.container__flex} onClick={hideModal}>
          <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <div className={s.button__close} onClick={hideModal}>
              <img src={closeIcon} alt="close__icon" />
            </div>

            <h4 className={s.title}>{t("ask price")}</h4>
            <p className={s.desc}>
              {t("enter details")} <br /> {t("configured model cost")}
            </p>

            <div className={s.accordion__wrapper}>
              <Collapsible
                trigger={t("your configuration")}
                triggerClassName={s.accordion__trigger}
                triggerOpenedClassName={clsx(s.accordion__trigger, s.accordion__trigger_open)}>
                <InfoContent />
              </Collapsible>
            </div>

            <OrderForm hideModal={hideModal} showModalSuccess={showModalSuccess} />
          </div>
        </div>
      </div>
    </div>
  );
};
