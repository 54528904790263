import React from "react";
import { SelectFormik } from "components/form/fields/selectFormik";
import { FormContainer } from "components/form/formContainer";
import { setHeightId, setWidthId } from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import s from "./index.module.scss";

const Dimensions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.configurator.loading);
  const heights = useSelector((state) => state.configurator.heights);
  const heightId = useSelector((state) => state.configurator.heightId);
  const optionsHeight = heights.map((item) => ({ value: item.id, label: item.name }));

  const widths = useSelector((state) => state.configurator.widths);
  const widthId = useSelector((state) => state.configurator.widthId);
  const optionsWidth = widths.map((item) => ({ value: item.id, label: item.name }));

  const initialValues = {
    selectedHeight: optionsHeight.find((item) => item?.value === heightId) || null,
    selectedWidth: optionsWidth.find((item) => item?.value === widthId) || null,
  };

  const handleSetHeightId = (e) => {
    dispatch(setHeightId(e.value));
  };

  const handleSetWidthId = (e) => {
    dispatch(setWidthId(e.value));
  };

  return (
    <div className={s.container}>
      <div className={s.inner}>
        <h4 className={s.title}>{t("dimensions")}</h4>

        <FormContainer enableReinitialize initialValues={initialValues}>
          {() => (
            <>
              <div className={s.height__wrapper}>
                <p className={s.label}>{t("height")}</p>
                <SelectFormik
                  isDisabled={loading}
                  name="selectedHeight"
                  options={optionsHeight}
                  onChange={handleSetHeightId}
                />
              </div>

              <div>
                <p className={s.label}>{t("width")}</p>
                <SelectFormik
                  isDisabled={loading}
                  name="selectedWidth"
                  options={optionsWidth}
                  onChange={handleSetWidthId}
                />
              </div>
            </>
          )}
        </FormContainer>
      </div>
    </div>
  );
};

export default Dimensions;
