import { useState } from "react";
import colorEmpty from "assets/img/common/color.png";
import checkIcon from "assets/img/common/check.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setInsideThresholdColorId, setOutsideThresholdColorId } from "modules/menu/redux";
import { baseURL } from "api";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

const ThresholdColor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationTab, setLocationTab] = useState("outside");
  const loading = useSelector((state) => state.configurator.loading);
  const outsideThresholdColors = useSelector((state) => state.configurator.outsideThresholdColors);
  const insideThresholdColors = useSelector((state) => state.configurator.insideThresholdColors);

  const outsideThresholdColorId = useSelector(
    (state) => state.configurator.outsideThresholdColorId
  );
  const insideThresholdColorId = useSelector((state) => state.configurator.insideThresholdColorId);

  const handleSetOutsideThresholdColorId = (item) => {
    if (!loading && !item?.locked) dispatch(setOutsideThresholdColorId(item.id));
  };

  const handleSetInsideThresholdColorId = (item) => {
    if (!loading && !item?.locked) dispatch(setInsideThresholdColorId(item.id));
  };

  return (
    <div className={s.container}>
      <div className={s.finish__inner}>
        <h4 className={s.finish__title}>{t("threshold")}</h4>

        <div className={s.tab__list}>
          <p
            onClick={() => setLocationTab("outside")}
            className={clsx(s.tab__item, locationTab === "outside" && s.active)}>
            {t("outside")}
          </p>
          <p
            onClick={() => setLocationTab("inside")}
            className={clsx(s.tab__item, locationTab === "inside" && s.active)}>
            {t("inside")}
          </p>
        </div>

        <div className={s.tab__content}>
          {locationTab === "outside" && (
            <div className={s.color__list}>
              {!!outsideThresholdColors.length &&
                outsideThresholdColors.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={s.color__item}
                      onClick={() => handleSetOutsideThresholdColorId(item)}>
                      <div className={clsx(s.color__img, item?.locked && s.locked)}>
                        {outsideThresholdColorId === item.id && (
                          <img className={s.color__check} src={checkIcon} alt="" />
                        )}

                        <img
                          className={clsx(s.color__image, item?.locked && s.locked)}
                          src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                          alt="color"
                        />
                      </div>
                      <p className={s.color__title}>{item.name}</p>
                    </div>
                  );
                })}
            </div>
          )}

          {locationTab === "inside" && (
            <div className={s.color__list}>
              {!!insideThresholdColors.length &&
                insideThresholdColors.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={s.color__item}
                      onClick={() => handleSetInsideThresholdColorId(item)}>
                      <div className={clsx(s.color__img, item?.locked && s.locked)}>
                        {insideThresholdColorId === item.id && (
                          <img className={s.color__check} src={checkIcon} alt="" />
                        )}
                        <img
                          className={clsx(s.color__image, item?.locked && s.locked)}
                          src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                          alt="color"
                        />
                      </div>
                      <p className={s.color__title}>{item.name}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThresholdColor;
