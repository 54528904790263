export const selectStyle = {
  control: () => ({
    height: "3.125rem",
    display: "flex",
    border: "1px solid #e3e3e3",
    fontSize: "0.875rem",
  }),
  option: (provided, state) => ({
    ...provided,
    minHeight: "3.125rem",
    padding: "0.9375rem",
    backgroundColor: state.isFocused ? "#E3E3E3" : "#fff",
    color: "#000000",
  }),

  singleValue: (provided) => ({
    ...provided,
    color: "#797979",
    fontSize: "0.875rem",
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: "0px",
  }),

  menuList: (provided) => ({
    ...provided,

    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "0.5rem",
  }),
};
