import { createSlice } from "@reduxjs/toolkit";

const sidebarConstructorSlice = createSlice({
  name: "sidebar",
  initialState: {
    activeSidebarId: "model",
  },
  reducers: {
    setActiveSidebarId(state, action) {
      state.activeSidebarId = action.payload.activeSidebarId;
    },
  },
});

export const { setActiveSidebarId } = sidebarConstructorSlice.actions;

export default sidebarConstructorSlice.reducer;
