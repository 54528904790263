import { useState } from "react";
import colorEmpty from "assets/img/common/color.png";
import checkIcon from "assets/img/common/check.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "api";
import { setInsideSlabId, setOutsideSlabId } from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

const FinishType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationTab, setLocationTab] = useState("outside");
  const loading = useSelector((state) => state.configurator.loading);

  const outsideSlabs = useSelector((state) => state.configurator.outsideSlabs);
  const outsideSlabId = useSelector((state) => state.configurator.outsideSlabId);

  const insideSlabs = useSelector((state) => state.configurator.insideSlabs);
  const insideSlabId = useSelector((state) => state.configurator.insideSlabId);

  const handleSetOutsideSlabId = (item) => {
    if (!loading && !item?.locked) dispatch(setOutsideSlabId(item.id));
  };

  const handleSetInsideSlabId = (item) => {
    if (!loading && !item?.locked) dispatch(setInsideSlabId(item.id));
  };

  return (
    <div className={s.container}>
      <div className={s.finish__inner}>
        <h4 className={s.finish__title}>{t("finishType")}</h4>
        <div className={s.tab__list}>
          <p
            onClick={() => setLocationTab("outside")}
            className={clsx(s.tab__item, locationTab === "outside" && s.active)}>
            {t("outside")}
          </p>
          <p
            onClick={() => setLocationTab("inside")}
            className={clsx(s.tab__item, locationTab === "inside" && s.active)}>
            {t("inside")}
          </p>
        </div>

        <div className={s.tab__content}>
          {locationTab === "outside" && (
            <div className={s.type__list}>
              {!!outsideSlabs.length &&
                outsideSlabs
                  .filter((item) => item.locked !== true)
                  .map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={s.type__item}
                        onClick={() => handleSetOutsideSlabId(item)}>
                        <div className={clsx(s.type__img, item?.locked && s.locked)}>
                          {outsideSlabId === item.id && (
                            <img className={s.check} src={checkIcon} alt="" />
                          )}

                          <img
                            className={clsx(s.type__image, item?.locked && s.locked)}
                            src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                            alt="type"
                          />
                        </div>
                        <p className={s.type__title}>{item.name}</p>
                      </div>
                    );
                  })}
            </div>
          )}

          {locationTab === "inside" && (
            <div className={s.type__list}>
              {!!insideSlabs.length &&
                insideSlabs
                  .filter((item) => item.locked !== true)
                  .map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={s.type__item}
                        onClick={() => handleSetInsideSlabId(item)}>
                        <div className={clsx(s.type__img, item?.locked && s.locked)}>
                          {insideSlabId === item.id && (
                            <img className={s.check} src={checkIcon} alt="" />
                          )}
                          <img
                            className={clsx(s.type__image, item?.locked && s.locked)}
                            src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                            alt="type"
                          />
                        </div>
                        <p className={s.type__title}>{item.name}</p>
                      </div>
                    );
                  })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinishType;
