import { useState } from "react";
import colorEmpty from "assets/img/common/color.png";
import checkIcon from "assets/img/common/check.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "api";
import { setInsideFrameColorId, setOutsideFrameColorId } from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

const MetalColor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.configurator.loading);
  const [locationTab, setLocationTab] = useState("outside");
  const outsideFrameColors = useSelector((state) => state.configurator.outsideFrameColors);
  const outsideFrameColorId = useSelector((state) => state.configurator.outsideFrameColorId);

  const insideFrameColors = useSelector((state) => state.configurator.insideFrameColors);
  const insideFrameColorId = useSelector((state) => state.configurator.insideFrameColorId);

  const handleSetOutsideFrameColorId = (item) => {
    if (!loading && !item?.locked) dispatch(setOutsideFrameColorId(item.id));
  };

  const handleSetInsideFrameColorId = (item) => {
    if (!loading && !item?.locked) dispatch(setInsideFrameColorId(item.id));
  };

  return (
    <div className={s.container}>
      <div className={s.metal__inner}>
        <h4 className={s.metal__title}>{t("metalColor")}</h4>

        <div className={s.tab__list}>
          <p
            onClick={() => setLocationTab("outside")}
            className={clsx(s.tab__item, locationTab === "outside" && s.active)}>
            {t("outside")}
          </p>
          <p
            onClick={() => setLocationTab("inside")}
            className={clsx(s.tab__item, locationTab === "inside" && s.active)}>
            {t("inside")}
          </p>
        </div>

        <div className={s.tab__content}>
          {locationTab === "outside" && (
            <div className={s.color__list}>
              {!!outsideFrameColors.length &&
                outsideFrameColors.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={s.color__item}
                      onClick={() => handleSetOutsideFrameColorId(item)}>
                      <div className={clsx(s.color__img, item?.locked && s.locked)}>
                        {outsideFrameColorId === item.id && (
                          <img className={s.color__check} src={checkIcon} alt="" />
                        )}

                        <img
                          className={clsx(s.color__image, item?.locked && s.locked)}
                          src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                          alt="color"
                        />
                      </div>
                      <p className={s.color__title}>{item.name}</p>
                    </div>
                  );
                })}
            </div>
          )}

          {locationTab === "inside" && (
            <div className={s.color__list}>
              {!!insideFrameColors.length &&
                insideFrameColors.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={s.color__item}
                      onClick={() => handleSetInsideFrameColorId(item)}>
                      <div className={clsx(s.color__img, item?.locked && s.locked)}>
                        {insideFrameColorId === item.id && (
                          <img className={s.color__check} src={checkIcon} alt="" />
                        )}
                        <img
                          className={clsx(s.color__image, item?.locked && s.locked)}
                          src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                          alt="color"
                        />
                      </div>
                      <p className={s.color__title}>{item.name}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MetalColor;
