import logo from "assets/img/header/logo.svg";
import logo2 from "assets/img/header/logo_B.png";
import { useTranslation } from "react-i18next";
import { Dropdown } from "./dropdown";
import { SocialShare } from "./../socialShare";
import { DownloadButton } from "./downloadButton";
import s from "./index.module.scss";

const Header = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <header className={s.header}>
      <img alt="logo" className={s.header__logo} src={logo2} />

      <div className={s.header__menu}>
        <DownloadButton />

        <Dropdown
          toggle={
            <button className={s.header__localization}>
              {i18n.language === "ru" ? "RU" : "EN"}
            </button>
          }>
          {i18n.language === "ru" ? (
            <button onClick={() => changeLanguage("en")} className={s.button__localization}>
              EN
            </button>
          ) : (
            <button onClick={() => changeLanguage("ru")} className={s.button__localization}>
              RU
            </button>
          )}
        </Dropdown>
        <SocialShare />
      </div>
    </header>
  );
};

export default Header;
