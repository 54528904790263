import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import clsx from "clsx";
import s from "./index.module.scss";

export const DisplayLoader = ({ hide }) => {
  return (
    <SkeletonTheme baseColor="#8d8f91" highlightColor="#c0c5c5">
      <div className={clsx(s.door__container, hide && s.hide)}>
        <div className={s.door__inner}>
          <Skeleton className={s.door} />
        </div>
      </div>
    </SkeletonTheme>
  );
};
