import React, { useState, useEffect } from "react";
import s from "./index.module.scss";

export const Dropdown = ({ children, toggle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener("click", () => setIsOpen(false));

    return function cleanup() {
      document.removeEventListener("click", () => setIsOpen(false));
    };
  }, []);

  return (
    <div className={s.dropdown__container} onClick={(e) => toggleDropdown(e)}>
      <div className={s.dropdown__toggle}>{toggle}</div>

      {isOpen && <div className={s.dropdown__content}>{children}</div>}
    </div>
  );
};
