import React, { useState } from "react";
import closeIcon from "assets/img/common/close.svg";
import checkIcon from "assets/img/common/check.svg";
import colorEmpty from "assets/img/common/color-empty.png";
import { baseURL } from "api";
import { useSelector, useDispatch } from "react-redux";
import {
  setInsideBackgroundFloorLayerId,
  setOutsideBackgroundFloorLayerId,
} from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import s from "./index.module.scss";

export const Floor = ({ setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationTab, setLocationTab] = useState("outside");
  const loading = useSelector((state) => state.configurator.loading);
  const backgroundFloorLayers = useSelector((state) => state.configurator.backgroundFloorLayers);

  const outsideBackgroundFloorLayerId = useSelector(
    (state) => state.configurator.outsideBackgroundFloorLayerId
  );
  const insideBackgroundFloorLayerId = useSelector(
    (state) => state.configurator.insideBackgroundFloorLayerId
  );

  const handleSetOutsideBackgroundFloorId = (item) => {
    if (!loading && !item?.locked) dispatch(setOutsideBackgroundFloorLayerId(item.id));
  };

  const handleSetInsideBackgroundFloorId = (item) => {
    if (!loading && !item?.locked) dispatch(setInsideBackgroundFloorLayerId(item.id));
  };

  return (
    <div className={s.container}>
      <div className={s.floor__heading}>
        <h4 className={s.floor__title}>{t("floor color")}</h4>

        <img
          src={closeIcon}
          alt="close__icon"
          className={s.close__icon}
          onClick={() => setOpen(false)}
        />
      </div>

      <div className={s.tab__list}>
        <p
          onClick={() => setLocationTab("outside")}
          className={clsx(s.tab__item, locationTab === "outside" && s.active)}>
          {t("outside")}
        </p>
        <p
          onClick={() => setLocationTab("inside")}
          className={clsx(s.tab__item, locationTab === "inside" && s.active)}>
          {t("inside")}
        </p>
      </div>

      <div className={s.tab__content}>
        {locationTab === "outside" && (
          <div className={s.color__list}>
            {!!backgroundFloorLayers.length &&
              backgroundFloorLayers.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={s.color__item}
                    onClick={() => handleSetOutsideBackgroundFloorId(item)}>
                    <div className={clsx(s.color__img, item?.locked && s.locked)}>
                      {outsideBackgroundFloorLayerId === item.id && (
                        <img className={s.check} src={checkIcon} alt="" />
                      )}

                      <img
                        className={clsx(s.color__image, item?.locked && s.locked)}
                        src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                        alt="type"
                      />
                    </div>
                    <p className={s.color__title}>{item.name}</p>
                  </div>
                );
              })}
          </div>
        )}

        {locationTab === "inside" && (
          <div className={s.color__list}>
            {!!backgroundFloorLayers.length &&
              backgroundFloorLayers.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={s.color__item}
                    onClick={() => handleSetInsideBackgroundFloorId(item)}>
                    <div className={clsx(s.color__img, item?.locked && s.locked)}>
                      {insideBackgroundFloorLayerId === item.id && (
                        <img className={s.check} src={checkIcon} alt="" />
                      )}

                      <img
                        className={clsx(s.color__image, item?.locked && s.locked)}
                        src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                        alt="type"
                      />
                    </div>
                    <p className={s.color__title}>{item.name}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
