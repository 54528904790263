import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import colorEmpty from "assets/img/common/color.png";
import checkIcon from "assets/img/common/check.svg";
import clsx from "clsx";
import { baseURL } from "api";
import { setInsideHardwareColorId, setOutsideHardwareColorId } from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

const HandlesColor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationTab, setLocationTab] = useState("outside");
  const loading = useSelector((state) => state.configurator.loading);
  const outsideHardwareColors = useSelector((state) => state.configurator.outsideHardwareColors);
  const outsideHardwareColorId = useSelector((state) => state.configurator.outsideHardwareColorId);

  const insideHardwareColors = useSelector((state) => state.configurator.insideHardwareColors);
  const insideHardwareColorId = useSelector((state) => state.configurator.insideHardwareColorId);

  const handleSetOutsideHardwareColorId = (item) => {
    if (!loading && !item?.locked) dispatch(setOutsideHardwareColorId(item.id));
  };

  const handleSetInsideHardwareColorId = (item) => {
    if (!loading && !item?.locked) dispatch(setInsideHardwareColorId(item.id));
  };

  return (
    <div className={s.container}>
      <div className={s.handles__inner}>
        <h4 className={s.handles__title}>{t("handlesColor")}</h4>

        {/* <div className={s.tab__list}>
          <p
            onClick={() => setLocationTab("outside")}
            className={clsx(s.tab__item, locationTab === "outside" && s.active)}>
            Снаружи
          </p>
          <p
            onClick={() => setLocationTab("inside")}
            className={clsx(s.tab__item, locationTab === "inside" && s.active)}>
            Внутри
          </p>
        </div> */}

        <div className={s.tab__content}>
          {locationTab === "outside" && (
            <div className={s.color__list}>
              {!!outsideHardwareColors.length &&
                outsideHardwareColors.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={s.color__item}
                      onClick={() => handleSetOutsideHardwareColorId(item)}>
                      <div className={clsx(s.color__img, item?.locked && s.locked)}>
                        {outsideHardwareColorId === item.id && (
                          <img className={s.color__check} src={checkIcon} alt="" />
                        )}

                        <img
                          className={clsx(s.color__image, item?.locked && s.locked)}
                          src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                          alt="color"
                        />
                      </div>
                      <p className={s.color__title}>{item.name}</p>
                    </div>
                  );
                })}
            </div>
          )}

          {locationTab === "inside" && (
            <div className={s.color__list}>
              {!!insideHardwareColors.length &&
                insideHardwareColors.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={s.color__item}
                      onClick={() => handleSetInsideHardwareColorId(item)}>
                      <div className={clsx(s.color__img, item?.locked && s.locked)}>
                        {insideHardwareColorId === item.id && (
                          <img className={s.color__check} src={checkIcon} alt="" />
                        )}

                        <img
                          className={clsx(s.color__image, item?.locked && s.locked)}
                          src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                          alt="color"
                        />
                      </div>
                      <p className={s.color__title}>{item.name}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HandlesColor;
