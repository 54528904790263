import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

export const InfoContent = () => {
  const { t } = useTranslation();
  const models = useSelector((state) => state.configurator.models);
  const modelId = useSelector((state) => state.configurator.modelId);
  const curModel = models.find((item) => item.id === modelId);
  const types = useSelector((state) => state.configurator.types);
  const selectedTypeId = useSelector((state) => state.configurator.selectedTypeId);
  const curType = types.find((item) => item.id === selectedTypeId);
  const styles = useSelector((state) => state.configurator.styles);
  const selectedStyleId = useSelector((state) => state.configurator.selectedStyleId);
  const curStyle = styles.find((item) => item.id === selectedStyleId);

  const outsideSlabs = useSelector((state) => state.configurator.outsideSlabs);
  const outsideSlabId = useSelector((state) => state.configurator.outsideSlabId);
  const curOutsideSlab = outsideSlabs.find((item) => item.id === outsideSlabId);
  const insideSlabs = useSelector((state) => state.configurator.insideSlabs);
  const insideSlabId = useSelector((state) => state.configurator.insideSlabId);
  const curInsideSlab = insideSlabs.find((item) => item.id === insideSlabId);

  const outsideSlabColors = useSelector((state) => state.configurator.outsideSlabColors);
  const outsideSlabColorId = useSelector((state) => state.configurator.outsideSlabColorId);
  const curOutsideSlabColor = outsideSlabColors.find((item) => item.id === outsideSlabColorId);
  const insideSlabColors = useSelector((state) => state.configurator.insideSlabColors);
  const insideSlabColorId = useSelector((state) => state.configurator.insideSlabColorId);
  const curInsideSlabColor = insideSlabColors.find((item) => item.id === insideSlabColorId);

  const peepholeTypes = useSelector((state) => state.configurator.peepholeTypes);
  const peepholeTypeId = useSelector((state) => state.configurator.peepholeTypeId);
  const curPeepholeType = peepholeTypes.find((item) => item.id === peepholeTypeId);

  const outsideHardwareColors = useSelector((state) => state.configurator.outsideHardwareColors);
  const outsideHardwareColorId = useSelector((state) => state.configurator.outsideHardwareColorId);
  const curOutsideHardwareColor = outsideHardwareColors.find(
    (item) => item.id === outsideHardwareColorId
  );

  const locks = useSelector((state) => state.configurator.locks);
  const lockId = useSelector((state) => state.configurator.lockId);
  const curLock = locks.find((item) => item.id === lockId);

  const additionalLocks = useSelector((state) => state.configurator.additionalLocks);
  const additionalLockId = useSelector((state) => state.configurator.additionalLockId);
  const curAdditionalLock = additionalLocks.find((item) => item.id === additionalLockId);

  const heights = useSelector((state) => state.configurator.heights);
  const heightId = useSelector((state) => state.configurator.heightId);
  const curHeight = heights.find((item) => item.id === heightId);
  const widths = useSelector((state) => state.configurator.widths);
  const widthId = useSelector((state) => state.configurator.widthId);
  const curWidth = widths.find((item) => item.id === widthId);

  const outsideFrameColors = useSelector((state) => state.configurator.outsideFrameColors);
  const insideFrameColors = useSelector((state) => state.configurator.insideFrameColors);
  const outsideFrameColorId = useSelector((state) => state.configurator.outsideFrameColorId);
  const insideFrameColorId = useSelector((state) => state.configurator.insideFrameColorId);
  const curOutsideFrameColor = outsideFrameColors.find((item) => item.id === outsideFrameColorId);
  const curInsideFrameColor = insideFrameColors.find((item) => item.id === insideFrameColorId);

  const outsideThresholdColors = useSelector((state) => state.configurator.outsideThresholdColors);
  const insideThresholdColors = useSelector((state) => state.configurator.insideThresholdColors);
  const outsideThresholdColorId = useSelector(
    (state) => state.configurator.outsideThresholdColorId
  );
  const insideThresholdColorId = useSelector((state) => state.configurator.insideThresholdColorId);
  const curOutsideThresholdColor = outsideThresholdColors.find(
    (item) => item.id === outsideThresholdColorId
  );
  const curInsideThresholdColor = insideThresholdColors.find(
    (item) => item.id === insideThresholdColorId
  );

  const casings = useSelector((state) => state.configurator.casings);
  const casingColorId = useSelector((state) => state.configurator.casingColorId);
  const curCasingColor = casings.find((item) => item.id === casingColorId);

  const hingesColors = useSelector((state) => state.configurator.hingesColors);
  const hingesColorId = useSelector((state) => state.configurator.hingesColorId);
  const curHingesColor = hingesColors.find((item) => item.id === hingesColorId);

  const leftOpening = useSelector((state) => state.configurator.leftOpening);

  return (
    <>
      <div className={s.box}>
        <h5 className={s.box__title}>
          {t("model")}: <span className={s.gray}>{curModel && curModel?.name}</span>
        </h5>
        <p className={s.box__item__title}>
          {t("door type")} <span className={s.gray}>{curType && curType?.name}</span>
        </p>
        <p className={s.box__item__title}>
          {t("style")} <span className={s.gray}>{curStyle && curStyle?.name}</span>
        </p>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>{t("finishType")}</h5>
        <p className={s.box__item__title}>
          {t("outside")}{" "}
          <span className={s.gray}>
            {curOutsideSlab?.name ? curOutsideSlab?.name : t("check with the manager")}
          </span>
        </p>
        <p className={s.box__item__title}>
          {t("inside")} <span className={s.gray}>{curInsideSlab && curInsideSlab?.name}</span>{" "}
        </p>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>{t("finishColor")}</h5>
        <p className={s.box__item__title}>
          {t("outside")}{" "}
          <span className={s.gray}>{curOutsideSlabColor && curOutsideSlabColor?.name}</span>
        </p>
        <p className={s.box__item__title}>
          {t("inside")}{" "}
          <span className={s.gray}>{curInsideSlabColor && curInsideSlabColor?.name}</span>{" "}
        </p>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>
          {t("peephole")}:{" "}
          <span className={s.gray}>{curPeepholeType && curPeepholeType?.name}</span>
        </h5>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>{t("dimensions")}</h5>
        <p className={s.box__item__title}>
          {t("height")} <span className={s.gray}>{curHeight && curHeight?.name}</span>
        </p>
        <p className={s.box__item__title}>
          {t("width")} <span className={s.gray}>{curWidth && curWidth?.name}</span>{" "}
        </p>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>
          {t("handlesColor")}:{" "}
          <span className={s.gray}>{curOutsideHardwareColor && curOutsideHardwareColor?.name}</span>
        </h5>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>{t("locks")}</h5>

        <p className={s.box__item__title}>
          {t("basic")} <span className={s.gray}>{curLock && curLock?.name}</span>
        </p>
        {curAdditionalLock?.name && (
          <p className={s.box__item__title}>
            {t("additional")} <span className={s.gray}>{curAdditionalLock.name}</span>
          </p>
        )}
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}> {t("metalColor")}</h5>
        <p className={s.box__item__title}>
          {t("outside")}{" "}
          <span className={s.gray}>{curOutsideFrameColor && curOutsideFrameColor?.name}</span>
        </p>
        <p className={s.box__item__title}>
          {t("inside")}{" "}
          <span className={s.gray}>{curInsideFrameColor && curInsideFrameColor?.name}</span>{" "}
        </p>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>{t("threshold")}</h5>
        <p className={s.box__item__title}>
          {t("outside")}{" "}
          <span className={s.gray}>
            {curOutsideThresholdColor && curOutsideThresholdColor?.name}
          </span>
        </p>
        <p className={s.box__item__title}>
          {t("inside")}{" "}
          <span className={s.gray}>{curInsideThresholdColor && curInsideThresholdColor?.name}</span>{" "}
        </p>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>
          {t("casingColor")}:{" "}
          <span className={s.gray}>{curCasingColor && curCasingColor?.name}</span>
        </h5>
      </div>

      <div className={s.box}>
        <h5 className={s.box__title}>
          {t("opening side")}:{" "}
          <span className={s.gray}>{leftOpening ? t("left") : t("right")}</span>
        </h5>
      </div>

      {/* <div className={s.box}>
        <h5 className={s.box__title}>
          {t("loops color")}:{" "}
          <span className={s.gray}>
            {curHingesColor?.name ? curHingesColor?.name : t("check with the manager")}
          </span>
        </h5>
      </div> */}
    </>
  );
};
