import React from "react";
import { useDispatch } from "react-redux";
import checkIcon from "assets/img/common/check.svg";
import colorEmpty from "assets/img/common/color.png";
import { useSelector } from "react-redux";
import { baseURL } from "api";
import { setModelIdConfig } from "modules/menu/redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import s from "./index.module.scss";

const Model = () => {
  const { t } = useTranslation();
  // const [filterIsOpen, setFilterIsOpen] = useState(false);
  const dispatch = useDispatch();

  const models = useSelector((state) => state.configurator.models);
  const modelId = useSelector((state) => state.configurator.modelId);
  const loading = useSelector((state) => state.configurator.loading);

  const handleSetModelId = (item) => {
    if (!loading && !item?.locked) dispatch(setModelIdConfig(item.id));
  };

  return (
    <div className={s.container}>
      {/* {filterIsOpen && <FiltersForm setFilterIsOpen={setFilterIsOpen} />} */}

      <div className={s.models__inner}>
        <h4 className={s.models__title}>{t("model")}</h4>

        {/* <button className={s.filter__button} onClick={() => setFilterIsOpen(true)}>
          <div className={s.filter__button__box}>
            <p className={s.filter__button__title}>{t("filters")}</p>

            <img src={filterIcon} alt="filter-icon" />
          </div>
        </button> */}

        <div className={s.model__list}>
          {!!models.length &&
            models.map((item) => {
              return (
                <div key={item.id} className={s.model__item} onClick={() => handleSetModelId(item)}>
                  <div className={s.model__img}>
                    {modelId === item.id && <img className={s.check} src={checkIcon} alt="" />}

                    <img
                      className={clsx(s.model__image, item?.locked && s.locked)}
                      src={item?.previewPath ? `${baseURL}${item.previewPath}` : colorEmpty}
                      alt="lock"
                    />
                  </div>
                  <p className={s.model__title}>{item.name}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Model;
