import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";

export const SidebarTrigger = ({ icon, title, isOpen }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(s.container, isOpen && s.opened)}>
      <img alt="icon" src={icon} />
      <span> {t(title)}</span>
    </div>
  );
};
