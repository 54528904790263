import { configureStore } from "@reduxjs/toolkit";
import sidebarConstructorReducer from "modules/sidebarConstructor/redux";
import configuratorReducer from "modules/menu/redux/index";

export default configureStore({
  reducer: {
    sidebarConstructor: sidebarConstructorReducer,
    configurator: configuratorReducer,
  },
});
