import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLeftOpening } from "modules/menu/redux";
import clsx from "clsx";
import s from "./index.module.scss";

const OpeningSide = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const leftOpening = useSelector((state) => state.configurator.leftOpening);
  const loading = useSelector((state) => state.configurator.loading);

  const handleSetLeftOpening = (isLeft) => {
    dispatch(setLeftOpening(isLeft));
  };

  return (
    <div className={s.container}>
      <div className={s.inner}>
        <h4 className={s.title}>{t("opening side")}</h4>

        <div className={s.tabs}>
          <button
            disabled={loading}
            onClick={() => handleSetLeftOpening(true)}
            className={clsx(s.tab__item, leftOpening && s.active)}>
            {t("left")}
          </button>
          <button
            disabled={loading}
            onClick={() => handleSetLeftOpening(false)}
            className={clsx(s.tab__item, !leftOpening && s.active)}>
            {t("right")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OpeningSide;
